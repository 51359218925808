var $class="se2--form-contact-usb-fo",$name="FormContactUsbFO",$path="app/components/FormContactUsbFO/index.js",$this={$class,$name,$path,};import onFormSubmitMessage from 'app/components/utilities/onFormSubmitMessage';
import prefillRadioButtonFromMarketo from 'app/components/utilities/prefillRadioButtonFromMarketo';
import prefillSelectFromMarketo from 'app/components/utilities/prefillSelectFromMarketo';
import sha256 from 'app/utilities/sha256';

import ajax from 'app/modules/ajax';
import analytics from 'app/modules/analytics';
import shell from 'app/modules/shell';

import 'app/partials/captcha';
import 'app/partials/checkbox';
import 'app/partials/fileUpload';
import 'app/partials/formComponentWrapper';
import 'app/partials/input';
import 'app/partials/notification';
import 'app/partials/radioBtn';
import 'app/partials/select';
import 'app/partials/submit';
import 'app/partials/textarea';
import 'app/partials/inputCountrySelector';

import getCookie from 'app/utilities/getCookie';
import getCountryLanguageCodes from 'app/utilities/getCountryLanguageCodes';

import { css } from 'configs';

import forEach from 'lodash/forEach';
import keys from 'lodash/keys';

const formHandlerConfigTcmUri = 'formHandlerConfigTcmUri';
const mktoCookie = '_mkto_trk';
const conditionalFieldSelector = '.conditional-field';

const validateStateField = (addEventListener, form) => {
  const countryField = form.querySelector('#Country');
  const stateFields = form.querySelectorAll('[name*=State_]');

  forEach(stateFields, (field) => {
    const conditionalField = field.closest(conditionalFieldSelector);
    conditionalField.classList.toggle(css.classNames.hide, conditionalField.dataset.fieldValue !== countryField.value);
    field.disabled = conditionalField.dataset.fieldValue !== countryField.value;

    addEventListener(field, 'change', ({ currentTarget }) => {
      if (form.state) form.state.value = currentTarget.value;
    });
  });

  addEventListener(countryField, 'change', () => {
    // Clear a value in hidden state field when a user switches a country
    form.state.value = '';
    // eslint-disable-next-line no-return-assign
    forEach(stateFields, (field) => field.value = '');
  });
};

const prefillFromMarketo = (addEventListener, form) => {
  if (form.dataset.enablePrefill === 'false') return;
  const tcm = form[formHandlerConfigTcmUri];

  if (getCookie(mktoCookie) && tcm && tcm.value) {
    const { mktoUrl } = form.dataset;
    if (mktoUrl) {
      ajax.fetchJSON(`${mktoUrl}?${formHandlerConfigTcmUri}=${tcm.value}`).then(
        (data) => {
          forEach(
            keys(data),
            (name) => {
              const field = form[name];

              if (field) {
                if (field.tagName && field.dataset.prefill === 'true') {
                // check if field is a select
                  if (field.length) {
                    prefillSelectFromMarketo(field, data[name]);
                    validateStateField(addEventListener, form);
                  // check if field is a checkbox
                  } else if (field.type === 'checkbox') {
                    field.checked = true;
                  // fields are input and textarea
                  } else {
                    field.value = data[name];
                  }
                // check if field is a radio(list of elements with mutual name)
                } else if (field instanceof HTMLCollection || field instanceof NodeList) {
                  prefillRadioButtonFromMarketo(field, data[name]);
                }
              }
            },
          );
        },
        () => {},
      );
    }
  }
};

export default shell.registerComponent($this, ({ addEventListener, configs, mount, settings, subscribeToMessage }) => {
  let form = null;

  const trackAnalytics = async () => {
    const { redirectUrl } = form.dataset;
    const { countryCode, languageCode } = settings.page;
    const actionUrl = form.getAttribute('action');
    const requestTypeURL = actionUrl.replace(`/${countryCode}/${languageCode}`, '');
    const formId = form.id;
    const formObj = Object.fromEntries(new FormData(form));
    const formEmail = formObj.Email || formObj.email || formObj.EmailOrPhone || formObj.emailOrPhone;
    const formPhone = formObj.Phone || formObj.phone;

    let userHashedEmail;
    await sha256(formEmail).then((hex) => { userHashedEmail = hex; });
    let userHashedPhone;
    await sha256(formPhone).then((hex) => { userHashedPhone = hex; });

    analytics.trackContactSubmit({
      requestTypeURL,
      formId,
      userHashedEmail,
      userHashedPhone,
      formObj,
      actionUrl,
    })
      .then(() => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      });
  };

  mount((element) => {
    const { countryCode, languageCode } = getCountryLanguageCodes(window.location.href);
    const elemHTML = document.getElementsByTagName('html')[0];
    const dirValue = elemHTML.getAttribute('dir');
    const pnInputPhone = document.querySelector('.pn-input-phone');
    const pnInputSelectClass = element.querySelector('.pn-select-wrapper')?.id;
    const pnInputSelect = element.querySelector(`#${pnInputSelectClass}`);

    // align the cursor to the text type for dir=rtl
    if (dirValue === 'rtl') {
      pnInputPhone.classList.remove('dirRtl');
      pnInputSelect.style.direction = 'ltr';

      forEach(document.getElementsByClassName('input-text'), (el) => {
        el.setAttribute('dir', 'auto');
        el.style.textAlign = 'right';
      });

      forEach(document.getElementsByClassName('textarea-common'), (el) => {
        el.setAttribute('dir', 'auto');
        el.style.textAlign = 'right';
      });

      document.getElementsByClassName('input-email')[0].setAttribute('dir', 'auto');
      document.getElementsByClassName('input-email')[0].style.textAlign = 'right';
    }

    form = element;
    prefillFromMarketo(addEventListener, form);
    validateStateField(addEventListener, form);
    subscribeToMessage(
      configs.messages.formSubmit,
      ({ status, formId }) => onFormSubmitMessage(form, formId, status, trackAnalytics),
    );

    // Hide contact form only on us/en locale if embedded chat is available
    // Note: To increase amount of countries where the logic below should be used
    // a new field with flag has to be created in SDL
    if (
      countryCode === configs.countryCodes.unitedStates
      && languageCode === configs.languageCodes.default
    ) {
      subscribeToMessage(
        configs.messages.embeddedChat,
        ({ state }) => {
          element
            .closest('[class*="form-component-wrapper"]')
            .classList
            .toggle(css.classNames.hide, state === 'available');
        },
      );
    }
    const snackbarContainer = document.querySelector('.snackbar-container');
    if (snackbarContainer !== undefined) {
      snackbarContainer?.classList.add('snackbar-margin');
    }
  });
});
