var $class="se2--form-contact-us-email",$name="FormContactUsEmail",$path="app/components/FormContactUsEmail/index.js",$this={$class,$name,$path,};import 'app/partials/captcha';
import 'app/partials/checkbox';
import 'app/partials/formComponentWrapper';
import 'app/partials/input';
import 'app/partials/notification';
import 'app/partials/radioBtn';
import 'app/partials/select';
import 'app/partials/submit';
import 'app/partials/textarea';
import shell from 'app/modules/shell';

export default shell.registerComponent($this, ({ mount }) => {
  mount(() => {
    const snackbarContainer = document.querySelector('.snackbar-container');
    if (snackbarContainer !== undefined) {
      snackbarContainer?.classList.add('snackbar-margin');
    }
  });
});
