var $class="se2--content-list-blocks",$name="ContentListBlocks",$path="app/components/ContentListBlocks/index.js",$this={$class,$name,$path,};import 'app/partials/image';
import shell from 'app/modules/shell';

export default shell.registerPartial($this, ({
  addEventListener, configs, mount,
}) => {
  mount((element) => {
    const onKeyDown = ({ code, target }) => {
      const { enter, numpadEnter, tab } = configs.keyNames;

      if (code === enter || code === numpadEnter) {
        target.querySelector('a').click();
      }
      if (code === tab) {
        target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    addEventListener(element, 'keydown', onKeyDown);
  });
});
