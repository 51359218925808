var $class="se2--utilities",$name="utilities",$path="app/components/utilities/initializeSliderForProductCards.js",$this={$class,$name,$path,};import {
  cardsContainerSelector,
  slideSelector,
  nextButtonSelector,
  prevButtonSelector,
  slideDuration,
  defaultSlideIndex,
  controlsSelector,
} from 'app/components/ContentProductBlocks/config';
import createSlidesOnKeydownListener from 'app/components/utilities/createSlidesOnKeydownListener';

import settings from 'app/modules/shell/settings';

import 'app/partials/image';

import capArrayIndex from 'app/utilities/capArrayIndex';

import {
  css,
  delays,
  directions,
  gsapAnimation,
  mouseButtons,
} from 'configs';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

import map from 'lodash/map';
import throttle from 'lodash/throttle';
import toogleControlVisibility from './toogleControlVisibility';

const defaultMinimumMovement = 2;

function initializeSliderForProductCards(addEventListener, element, isDefaultMinimumMovement = true) {
  const slides = element.querySelectorAll(slideSelector);

  if (!slides.length) {
    element.classList.add(css.classNames.hide);
    return;
  }

  const phantomElement = document.createElement('div');
  const cardsContainer = element.querySelector(cardsContainerSelector);
  const nextButton = element.querySelector(nextButtonSelector);
  const prevButton = element.querySelector(prevButtonSelector);
  const controlsElement = element.querySelector(controlsSelector);
  const cardElements = element.querySelectorAll(slideSelector);

  let dragStart = 0;
  let activeSlide = 0;
  let positions = map(slides, (slide) => {
    const slidePosition = settings.direction === directions.rtl
      ? slide.getBoundingClientRect().right - cardsContainer.getBoundingClientRect().right
      : slide.offsetLeft - cardsContainer.offsetLeft;

    return slidePosition;
  });

  const selectSlide = (index) => {
    activeSlide = capArrayIndex(index, slides);

    gsap.to(slides, {
      x: -positions[activeSlide],
      duration: slideDuration,
    });
  };

  toogleControlVisibility(cardElements, cardsContainer, controlsElement);

  addEventListener(prevButton, 'click', throttle(() => {
    if (activeSlide !== 0) {
      selectSlide(activeSlide - 1);
    }
  }, delays.ms300));

  addEventListener(nextButton, 'click', throttle(() => {
    if (cardsContainer.scrollWidth - cardsContainer.clientWidth > 0 && activeSlide + 1 !== slides.length) {
      selectSlide(activeSlide + 1);
    }
  }, delays.ms300));

  const onDragStart = ({ clientX }) => {
    dragStart = clientX;
  };

  const onDragEnd = ({ clientX }) => {
    const offset = settings.direction === directions.rtl
      ? clientX - dragStart
      : dragStart - clientX;

    if (offset < 0 && activeSlide !== 0) {
      selectSlide(activeSlide - 1);
    } else if (
      offset > 0 && cardsContainer.scrollWidth - cardsContainer.clientWidth > 0 && activeSlide !== slides.length - 1
    ) {
      selectSlide(activeSlide + 1);
    }
  };

  const onClick = (event) => {
    if (event.button === mouseButtons.middle) {
      event.preventDefault();
    }
  };

  selectSlide(defaultSlideIndex);
  addEventListener(cardsContainer, 'keydown', createSlidesOnKeydownListener(prevButton, nextButton));

  // eslint-disable-next-line consistent-return
  return {
    draggable: new Draggable(
      phantomElement,
      {
        type: 'x',
        throwProps: true,
        minimumMovement: isDefaultMinimumMovement
          ? defaultMinimumMovement
          : window.innerWidth * gsapAnimation.recognitionRate,
        allowContextMenu: true,
        dragClickables: true,
        cursor: 'default',
        activeCursor: 'grabbing',
        trigger: cardsContainer.parentElement,
        onDragStart,
        onDragEnd,
        onClick,
      },
    ),
    onResize: () => {
      const cardsContainerOffsetRight = cardsContainer.offsetLeft + cardsContainer.offsetWidth;

      positions = map(slides, (slide) => {
        toogleControlVisibility(cardElements, cardsContainer, controlsElement);
        const slideOffsetRight = slide.offsetLeft + slide.offsetWidth;

        return settings.direction === directions.rtl
          ? slideOffsetRight - cardsContainerOffsetRight
          : slide.offsetLeft - cardsContainer.offsetLeft;
      });

      gsap.set(slides, {
        x: -positions[activeSlide],
      });
    },
  };
}

export default initializeSliderForProductCards;
