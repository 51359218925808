var $class="se2--utilities",$name="utilities",$path="app/components/utilities/createSlidesOnKeydownListener.js",$this={$class,$name,$path,};import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';
import isUsingKeyboard from 'app/utilities/isUsingKeyboard';

import { keyNames } from 'configs';

import { slideClassName } from 'app/components/ContentFlexibleBlocks/config';

import isFunction from 'lodash/isFunction';

const createSlidesOnKeydownListener = (prevButton, nextButton, verticalOrientation = false) => (event) => {
  const isVerticalNavigation = isFunction(verticalOrientation)
    ? verticalOrientation()
    : verticalOrientation;

  if (isUsingKeyboard()) {
    const { code, target } = event;
    const { arrowDown, arrowLeft, arrowRight, arrowUp, enter } = keyNames;

    if (code === (isVerticalNavigation ? arrowDown : arrowRight)) {
      event.preventDefault();
      const nextElement = target.nextElementSibling;
      nextElement && switchFocusToTargetElement(nextElement, target);
      nextButton?.click();
    } else if (code === (isVerticalNavigation ? arrowUp : arrowLeft)) {
      event.preventDefault();
      const previousElement = target.previousElementSibling;
      previousElement && switchFocusToTargetElement(previousElement, target);
      prevButton?.click();
    } else if (code === enter) {
      if (target.classList.contains(slideClassName)) {
        const link = target.querySelector('a');
        link.click();
      }
    }
  }
};

export default createSlidesOnKeydownListener;
