var $class="se2--utilities",$name="utilities",$path="app/components/utilities/onNavigation.js",$this={$class,$name,$path,};import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import setTabIndex from 'app/utilities/setTabIndex';
import switchFocusToTargetElement from 'app/utilities/switchFocusToTargetElement';
import { keyNames, css } from 'configs';
import forEach from 'lodash/forEach';

const createCardsKeyDownListener = (slideSelector) => (event) => {
  if (isUsingKeyboard()) {
    const { code, target } = event;
    if (code === keyNames.arrowRight) {
      event.preventDefault();
      switchFocusToTargetElement(target.closest(slideSelector)?.nextElementSibling, target);
    } else if (code === keyNames.arrowLeft) {
      event.preventDefault();
      switchFocusToTargetElement(target.closest(slideSelector)?.previousElementSibling, target);
    } else if (code === keyNames.tab) {
      const interactiveElements = target.parentNode.querySelectorAll(`.${css.classNames.button}`);
      if (interactiveElements.length) {
        forEach(interactiveElements, setTabIndex);
      }
    }
  }
};

export default createCardsKeyDownListener;
