var $class="se2--input-country-selector",$name="inputCountrySelector",$path="app/partials/inputCountrySelector/config.js",$this={$class,$name,$path,};export const regExpGetHref = /^.{8}[^/]*\/([^/]*)/;
export const regexPhoneNumber = /[~`!#$%^&*=\-[\]\\';,/{}()|\\":<>.?]/;
export const pnSelectWrapperClass = 'pn-select-wrapper';
export const pnDropdownWrapperClass = 'pn-dropdown-wrapper';
export const pnInputPrefixClass = 'pn-input_prefix';
export const pnInputPhoneClass = 'pn-input-phone';
export const pnTriggerDropdown = 'pn-selected-prefix';
export const pnListItemClass = '.pn-list-item';
export const pnListItemSelectedClass = 'pn-list-item--selected';
export const searchTextClass = 'search-text';
export const mapApi = 'https://nominatim.openstreetmap.org/reverse?lat=';
