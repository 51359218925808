var $class="se2--container-accordion",$name="ContainerAccordion",$path="app/components/ContainerAccordion/index.js",$this={$class,$name,$path,};import 'app/components/ContentListBlocks';
import 'app/components/ContentFlexibleBlocks';
import 'app/components/ContentProductBlocks';
import 'app/components/FormContactUsbFO';
import 'app/components/FormContactUsEmail';

import shell from 'app/modules/shell';

import 'app/partials/image';

import getTotalStickyHeight from 'app/utilities/getTotalStickyHeight';
import parseDecimal from 'app/utilities/parseDecimal';

import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';

const accordionSelector = `.${$class}`;
const itemSelector = '.subsegment';
const itemContentSelector = '.accordion-content';
const itemControlSelector = '.accordion-control';
const expandedClassName = 'expanded';
const scrollTimeout = 500;

export { expandedClassName, itemSelector, itemContentSelector, itemControlSelector };

export default shell.registerComponent($this, ({ addEventListener, elements, mount, configs }) => {
  const onWindowResize = debounce(
    () => forEach(elements, (element) => {
      const content = element.querySelector(`.${expandedClassName} ${itemContentSelector}`);
      if (content) {
        content.style.blockSize = 'auto';
        content.style.blockSize = `${content.scrollHeight}px`;
      }
    }),
    300,
  );

  const hideTabWhenEmpty = () => forEach(elements, (element) => {
    forEach(element.querySelectorAll(itemContentSelector), (tabElement) => {
      if (isEqual(tabElement.scrollHeight, 0)) {
        tabElement.closest(itemSelector)
          .classList
          .add(configs.css.classNames.hide);
      }
    });
  });

  function onElementClick({ target }) {
    const control = target.closest(itemControlSelector);
    if (!control) return;

    const element = target.closest(accordionSelector);
    const items = element.querySelectorAll(itemSelector);
    const item = control.closest(itemSelector);

    forEach(items, (sibling) => {
      // hide previous open item
      if (sibling.classList.contains(expandedClassName) && sibling !== item) {
        sibling.classList.remove(expandedClassName);
        // set block-size for animation
        sibling.querySelector(itemContentSelector).style.blockSize = 0;
        sibling.querySelector('button').setAttribute('aria-expanded', 'false');
      }
    });

    const openedContentElement = element.querySelector(`.${expandedClassName} ${itemContentSelector}`);
    const openedContentElementBlockSize = openedContentElement
      ? parseDecimal(openedContentElement.style.blockSize)
      : 0;

    const isExpanded = item.classList.contains(expandedClassName);
    item.classList.toggle(expandedClassName, !isExpanded);
    control.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');

    if (item.classList.contains(expandedClassName)) {
      const totalStickyHeight = getTotalStickyHeight();
      const getScrollToValue = (topIndent) => window.pageYOffset
          + item.getBoundingClientRect().top
          - topIndent
          - openedContentElementBlockSize;
      const scrollToValue = getScrollToValue(totalStickyHeight);

      setTimeout(() => {
        window.scrollTo(0, scrollToValue);
        // There is a case when sticky elements stuck between themselves when scrolling, hovewer their
        // heights weren't be included in totalStickyHeight value. So, we should update the value
        // and recall scrollTo function
        setTimeout(() => {
          const totalStickyHeightAfterScroll = getTotalStickyHeight();
          // Recheck total sticky height after scroll
          if (totalStickyHeightAfterScroll !== totalStickyHeight) {
            window.scrollTo(0, getScrollToValue(totalStickyHeightAfterScroll));
          }
        }, scrollTimeout);
      }, scrollTimeout);
    }

    const content = item.querySelector(itemContentSelector);
    // set block-size for animation
    content.style.blockSize = item.classList.contains(expandedClassName)
      ? `${content.scrollHeight}px`
      : '0';
  }

  addEventListener(window, 'resize', onWindowResize);

  mount((element) => {
    hideTabWhenEmpty();
    addEventListener(element, 'click', onElementClick);
  });
});
