var $class="se2--content-product-blocks",$name="ContentProductBlocks",$path="app/components/ContentProductBlocks/index.js",$this={$class,$name,$path,};import initializeSliderForProductCards from 'app/components/utilities/initializeSliderForProductCards';

import shell from 'app/modules/shell';
import 'app/partials/image';
import createCardsKeyDownListener from 'app/components/utilities/onNavigation';

const cardsContainerSelector = '.cards-container';
const slideSelector = '.slide';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const slider = initializeSliderForProductCards(addEventListener, element);
    if (slider) {
      addEventListener(
        element.querySelector(cardsContainerSelector),
        'keydown',
        createCardsKeyDownListener(slideSelector),
      );
      addEventListener(window, 'resize', slider.onResize);
    }
  });
});
