var $class="se2--input-country-selector",$name="inputCountrySelector",$path="app/partials/inputCountrySelector/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import ajax from 'app/modules/ajax';

import { css } from 'configs';
import { getCountryCallingCode, isValidPhoneNumber, parsePhoneNumberFromString, parsePhoneNumber }
  from 'libphonenumber-js';
import { pnSelectWrapperClass, pnDropdownWrapperClass, pnInputPrefixClass, pnInputPhoneClass, regExpGetHref,
  pnTriggerDropdown, pnListItemClass, pnListItemSelectedClass, searchTextClass, mapApi,
  regexPhoneNumber } from './config';

export const toggleInvalid = (element, show) => element
  .closest('.form-group').classList.toggle(css.classNames.invalid, show);

const toggleIncorrect = (element, show) => element.closest('.form-group').classList.toggle('incorrect', show);

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const selectContainerWrapper = element.getElementsByClassName(pnSelectWrapperClass)[0].id;
    // const selectContainerWrapper = document.getElementsByClassName(pnSelectWrapperClass)[0].id;
    const selectContainer = element.querySelector(`#${selectContainerWrapper}`);
    const pnDropdownId = element.getElementsByClassName(pnDropdownWrapperClass)[0].id;
    const pnTriggerDropdownId = element.getElementsByClassName(pnTriggerDropdown)[0].id;
    const dropdownContainer = element.querySelector(`#${pnDropdownId}`);
    const selectedFlagId = element.getElementsByClassName(pnInputPrefixClass)[0].id;
    const selectedFlag = element.querySelector(`#${selectedFlagId}`);
    const selectedCountryCodeId = element.getElementsByClassName(pnInputPhoneClass)[0].id;
    const selectedCountryCode = element.querySelector(`#${selectedCountryCodeId}`);
    const searchForCountryClass = element.getElementsByClassName(searchTextClass)[0].id;
    const searchForCountry = element.querySelector(`#${searchForCountryClass}`);
    const elemHTML = document.getElementsByTagName('html')[0];
    const dirValue = elemHTML.getAttribute('dir');
    const pnInputPhone = element.querySelector('.pn-input-phone');

    if (dirValue === 'rtl') {
      pnInputPhone.classList.add('dirRtl');
    }

    const onChange = ({ target }) => {
      const phoneValue = target.value;
      const checkValid = isValidPhoneNumber(phoneValue, selectedFlag.value);
      toggleIncorrect(target, !checkValid);
      if (checkValid) {
        const phoneNumber = parsePhoneNumberFromString(phoneValue);
        if (phoneNumber === undefined) {
          toggleIncorrect(target, checkValid);
        }
        target.value = phoneValue.replace(/ /g, '');
        if (phoneValue.length > 10 && phoneValue.substring(0, 2) === '00') {
          target.value = phoneValue.replace(/^.{2}/g, '+');
        }
      }
      if (phoneValue.length <= 4) {
        if (phoneValue.startsWith('+') || phoneValue === '') {
          toggleIncorrect(target, checkValid);
        }
      }
    };

    const setNewSelected = (prefix, code) => {
      selectedFlag.value = prefix;
      selectedCountryCode.value = `${code}`;
      selectContainer.style.setProperty('--prefix-length', prefix.length);
    };

    const dropdownTrigger = element.querySelector(`#${pnTriggerDropdownId}`);
    const listItems = [...element.querySelectorAll(pnListItemClass)];

    addEventListener(dropdownTrigger, 'click', () => {
      dropdownContainer.classList.toggle('open');
      searchForCountry.value = '';
      listItems.forEach((item) => {
        item.style.display = '';
      });
    });

    const selectCountry = (e) => {
      const { code, prefix } = e.target.closest('li').dataset;
      setNewSelected(prefix, code);
      dropdownContainer.classList.toggle('open');
    };

    window.onclick = (e) => {
      if (!e.target.matches(`.${pnInputPrefixClass}`)
      && !e.target.matches(`#${searchForCountryClass}`) && !e.target.matches(`#${pnTriggerDropdownId}`)) {
        if (dropdownContainer?.classList.contains('open')) {
          dropdownContainer.classList.remove('open');
        }
      }
    };

    listItems.forEach((item) => {
      addEventListener(item, 'click', (e) => {
        const previousSelected = element.getElementsByClassName(
          pnListItemSelectedClass,
        );
        if (item.classList.contains(pnListItemSelectedClass)) {
          item.classList.remove(pnListItemSelectedClass);
        } else {
          if (previousSelected.length > 0) {
            previousSelected[0].classList.remove(pnListItemSelectedClass);
          }
          item.classList.add(pnListItemSelectedClass);
        }
        selectCountry(e);
      });
    });

    const setDefaultCountryCode = () => {
      const currentURL = window.location.href;
      const countrycode = (regExpGetHref.exec(currentURL)[1]).toUpperCase();
      const splitURL = currentURL.split('/');

      const setFlagForNonListedCountries = () => {
        switch (true) {
          case countrycode === 'WW':
            if (splitURL[4] === 'en') {
              selectedFlag.value = 'US';
            } else {
              selectedFlag.value = splitURL[4].toUpperCase();
            }
            break;
          case countrycode.length > 3:
            selectedFlag.value = splitURL[4].toUpperCase();
            break;
          case countrycode === 'UK':
            selectedFlag.value = 'GB';
            break;
          default:
            if (listItems.some((el) => el.dataset.prefix.includes(countrycode))) {
              selectedFlag.value = countrycode;
            } else {
              selectedFlag.value = 'US';
            }
        }
      };

      const showPosition = (position) => {
        ajax.fetchJSON(`${mapApi}${position.coords.latitude}&lon=${position.coords.longitude}&zoom=3&format=json`)
          .then((res) => {
            const resCountryCode = res.address.country_code.toUpperCase();
            listItems.forEach((item) => {
              switch (true) {
                case item.dataset.prefix === resCountryCode:
                  selectedFlag.value = resCountryCode;
                  break;
                default:
                  setFlagForNonListedCountries();
              }

              if (item.dataset.prefix === selectedFlag.value) {
                item.classList.add(pnListItemSelectedClass);
              }
            });
            const countryCallingCode = getCountryCallingCode(selectedFlag.value);
            selectedCountryCode.value = `+${countryCallingCode}`;
          });
      };

      function geoPermissionDenied() {
        setFlagForNonListedCountries();
        listItems.forEach((item) => {
          if (item.dataset.prefix === selectedFlag.value) {
            item.classList.add(pnListItemSelectedClass);
          }
        });
        const countryCallingCode = getCountryCallingCode(selectedFlag.value);
        selectedCountryCode.value = `+${countryCallingCode}`;
      }
      // BEGIN-NOSCAN
      // Accepted this exception in sonar, the logic is mandatory
      navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation?.getCurrentPosition(showPosition, geoPermissionDenied);
        } else {
          geoPermissionDenied();
        }
      });
      // END-NOSCAN
    };

    const isPhoneValid = (e) => {
      const phoneValue = e.target.value;
      const isValid = isValidPhoneNumber(phoneValue, selectedFlag.value);
      try {
        const phoneNumber = parsePhoneNumber(phoneValue);
        const formattedPhoneNumber = phoneNumber.formatInternational();
        listItems.forEach((item) => {
          if (item.dataset.code === `+${phoneNumber.countryCallingCode}`) {
            selectedFlag.value = item.dataset.prefix;
          }
        });
        if (phoneNumber) {
          listItems.forEach((item) => {
            if (item.dataset.prefix === phoneNumber.country) {
              item.classList.add(pnListItemSelectedClass);
            } else {
              item.classList.remove(pnListItemSelectedClass);
            }
          });
          const checkValid = isValidPhoneNumber(formattedPhoneNumber);
          toggleIncorrect(e.target, !checkValid);
        }
        if (isValid) {
          if (regexPhoneNumber.test(phoneValue)) {
            toggleIncorrect(e.target, isValid);
          }
        } else {
          toggleIncorrect(e.target, !isValid);
        }
      } catch (el) {
        if (phoneValue.length <= 4) {
          if (phoneValue.startsWith('+') || phoneValue === '') {
            toggleIncorrect(e.target, false);
          }
        } else {
          toggleIncorrect(e.target, true);
        }
      }
    };

    const searchCountryText = () => {
      const filterText = searchForCountry.value.toUpperCase();
      const pattern = new RegExp(`\\b${filterText}`, 'i');
      listItems.forEach((item) => {
        const txtValue = item.innerText;
        if (pattern.test(txtValue) === true) {
          item.style.display = '';
        } else {
          item.style.display = 'none';
        }
      });
    };

    const onKeyDown = ({ target }) => {
      toggleInvalid(target, false);
    };

    addEventListener(selectedCountryCode, 'change', onChange);
    addEventListener(selectedCountryCode, 'keyup', isPhoneValid);
    addEventListener(searchForCountry, 'keyup', searchCountryText);
    addEventListener(element, 'keydown', onKeyDown);
    setDefaultCountryCode();
  });
});
